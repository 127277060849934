import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';

import SubscriptionListTable from '../../components/SubscriptionListTable';
import UserCouponListTable from '../../components/UserCouponListTable';
import VoucherListTable from '../../components/VoucherListTable';
import axios from 'axios';
import config from '../../config';

export const UserDetailPage = (props) => {
  const [userData, setUserData] = useState(null);
  const {
    match: {
      params: { userId },
    },
  } = props;

  const getUserData = (userId) => {
    const endpoint = `${config.API_BASE}/admin/users/users/${userId}`;
    axios
      .get(endpoint)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserData(userId);
  }, [userId]);

  return (
    <>
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>회원 상세</CCardHeader>
            <CCardBody>
              {userData && (
                <>
                  <CRow>
                    <CCol md={4}>회원 번호</CCol>
                    <CCol>{userData.id}</CCol>
                  </CRow>
                  <CRow>
                    <CCol md={4}>Email</CCol>
                    <CCol>{userData.email}</CCol>
                  </CRow>
                </>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>회원에게 등록된 쿠폰 목록</CCardHeader>
            <CCardBody>
              <UserCouponListTable
                search={{ user_id: userId }}
                readOnlyUserId={true}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>회원에게 등록된 이용권</CCardHeader>
            <CCardBody>
              <VoucherListTable
                search={{ user_id: userId }}
                readOnlyUserId={true}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>회원의 강의 수강 목록</CCardHeader>
            <CCardBody>
              <SubscriptionListTable
                search={{ user_id: userId }}
                readOnlyUserId={true}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
