import {
  CCollapse,
  CNav,
  CNavItem,
  CNavLink,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CToggler,
} from '@coreui/react';

import React from 'react';

const CouponListNav = () => (
  <>
    <CNavbar
      expandable="sm"
      color="faded"
      light
      style={{
        border: '1px solid #d8dbe0',
        backgroundColor: '#ffffff',
        borderRadius: '0.25rem',
        marginBottom: '1.5rem',
      }}
    >
      <CToggler inNavbar />
      <CNavbarBrand>쿠폰</CNavbarBrand>
      <CCollapse show navbar>
        <CNavbarNav className="ml-auto" />

        <CNav>
          <CNavItem>
            <CNavLink active to="/coupons/coupons/list">
              쿠폰 목록
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink to="/coupons/coupons/create">쿠폰 생성</CNavLink>
          </CNavItem>
        </CNav>
      </CCollapse>
    </CNavbar>
  </>
);

CouponListNav.defaultProps = {};

CouponListNav.propTypes = {};

export default CouponListNav;
