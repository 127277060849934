import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import UserCouponListTable from '../../components/UserCouponListTable';
import axios from 'axios';
import config from '../../config';
import { withRouter } from 'react-router-dom';
import CouponNav from '../../components/CouponNav';

const CouponUserCouponListPage = (props) => {
  const { id: couponId } = props.match.params;

  const [couponStats, setCouponStats] = useState({});

  const getCouponStats = async () => {
    const endpoint = `${config.API_BASE}/admin/coupons/coupons/${couponId}/stats`;

    try {
      const res = await axios.get(endpoint);
      setCouponStats(res.data.data);
    } catch (e) {
      console.log(e);
      setCouponStats(null);
    }
  };

  useEffect(() => {
    getCouponStats();
  }, []);

  return (
    <>
      <CouponNav couponId={couponId} couponStats={couponStats} />

      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <i className="fa fa-align-justify" /> 등록 쿠폰 목록
            </CCardHeader>
            <CCardBody>
              <UserCouponListTable
                search={{ coupon_id: couponId }}
                readOnlyCouponId={true}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default withRouter(CouponUserCouponListPage);
