import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { CouponDetail } from '../../components/CouponDetail';
import UserCouponNav from '../../components/UserCouponNav';
import axios from 'axios';
import config from '../../config';
import moment from 'moment';
import { UserInline } from '../../components/UserInline';

const UserCouponDetailPage = (props) => {
  const {
    match: {
      params: { userCouponId },
    },
  } = props;
  const [userCouponDetail, setUserCouponDetail] = useState({});
  const [couponCodeDetail, setCouponCodeDetail] = useState({});
  const [couponDetail, setCouponDetail] = useState(null);
  const [courseData, setCourseData] = useState([]);

  const getUserCouponDetail = (couponId) => {
    const endpoint = `${config.API_BASE}/admin/coupons/user-coupons/${userCouponId}`;
    axios
      .get(endpoint)
      .then((res) => {
        console.log(res);

        const userCoupon = res.data.data;
        const _couponCodeDetail = userCoupon.coupon_code;

        setUserCouponDetail(userCoupon);
        setCouponCodeDetail(_couponCodeDetail);

        const _couponId = _couponCodeDetail.coupon.id;
        const couponDetailEndpoint = `${config.API_BASE}/admin/coupons/coupons/${_couponId}`;

        axios
          .get(couponDetailEndpoint)
          .then((res) => {
            setCouponDetail(res.data.data);
          })
          .catch((err) => {
            console.log(err);
            alert(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //
  const getCourseData = () => {
    const endpoint = `${config.API_BASE}/admin/lectures/courses/ids`;
    axios
      .get(endpoint)
      .then((res) => {
        setCourseData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //
  useEffect(() => {
    getUserCouponDetail(userCouponId);
    getCourseData();
  }, [userCouponId]);

  return (
    <div className="animated fadeIn">
      <UserCouponNav />

      {userCouponDetail && (
        <CCard>
          <CCardHeader>
            <div className="badge-valign">
              <i
                className="fa fa-align-justify"
                style={{ marginRight: '8px' }}
              />
              등록쿠폰 상세 <CBadge color="primary">{userCouponDetail.id}</CBadge>
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md={2}>사용자</CCol>
              <CCol>
                <UserInline userId={userCouponDetail.user_id} />
              </CCol>
            </CRow>
            <CRow>
              <CCol md={2}>생성 일시</CCol>
              <CCol>
                {moment(userCouponDetail.created_at).format(
                  'YYYY-MM-DD hh:mm:ss a',
                )}
              </CCol>
              <CCol md={2}>사용 만료 일시</CCol>
              <CCol>
                {moment(userCouponDetail.expires_at).format(
                  'YYYY-MM-DD hh:mm:ss a',
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol md={2}>사용 여부</CCol>
              <CCol>
                {userCouponDetail.is_used ? (
                  <CBadge color="success">사용 완료</CBadge>
                ) : (
                  <CBadge color="danger">미사용</CBadge>
                )}
              </CCol>

              <CCol md={2}>사용 일시</CCol>
              <CCol>
                {userCouponDetail.used_at ? (moment(userCouponDetail.used_at).format(
                  'YYYY-MM-DD hh:mm:ss a',
                )) : '-'}
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      )}

      {couponDetail && (
        <CCard>
          <CCardHeader>
            <div className="badge-valign">
              <i
                className="fa fa-align-justify"
                style={{ marginRight: '8px' }}
              />
              쿠폰 상세 <CBadge color="primary">{couponDetail.id}</CBadge>
            </div>
          </CCardHeader>
          <CCardBody>
            <CouponDetail
              courseData={courseData}
              {...couponDetail}
            />
          </CCardBody>
        </CCard>
      )}

      {couponCodeDetail && (
        <CCard>
          <CCardHeader>
            <div className="badge-valign">
              <i
                className="fa fa-align-justify"
                style={{ marginRight: '8px' }}
              />
              쿠폰 코드 상세{' '}
              <CBadge color="primary">{couponCodeDetail.id}</CBadge>
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md={2}>ID</CCol>
              <CCol>{couponCodeDetail.id}</CCol>
            </CRow>
            <CRow>
              <CCol md={2}>CODE</CCol>
              <CCol>
                <code>{couponCodeDetail.code}</code>
              </CCol>
            </CRow>
            <CRow>
              <CCol md={2}>생성일</CCol>
              <CCol>
                {moment(couponCodeDetail.created_at).format(
                  'YYYY-MM-DD hh:mm:ss a',
                )}
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      )}


      <CCard>
        <CCardHeader>
          <div className="badge-valign">
            <i
              className="fa fa-align-justify"
              style={{ marginRight: '8px' }}
            />
            이용권
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow>
            {
              userCouponDetail.vouchers && userCouponDetail.vouchers.map(
                (el, idx) => {
                  return (
                    <CCol md={6} key={el.id}>
                      <CCard>
                        <CCardHeader>
                          <div className="badge-valign">
                            <i
                              className="fa fa-align-justify"
                              style={{ marginRight: '8px' }}
                            />
                            이용권{' '}
                            <CBadge color="primary">{el.id}</CBadge>
                          </div>
                        </CCardHeader>
                        <CCardBody>
                          <CRow>
                            <CCol md={2}>이름</CCol>
                            <CCol>
                              {el.name}
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol md={2}>출처</CCol>
                            <CCol>
                              {JSON.stringify(el.source, null, 2)}
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol md={2}>생성일</CCol>
                            <CCol>
                              {moment(el.created_at).format(
                                'YYYY-MM-DD hh:mm:ss a',
                              )}
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol md={2}>사용 만료일</CCol>
                            <CCol>
                              {moment(el.expires_at).format(
                                'YYYY-MM-DD hh:mm:ss a',
                              )}
                            </CCol>
                          </CRow>
                          <hr />
                          <CRow><CCol><h5>쿠폰 혜택</h5></CCol></CRow>
                          <CRow>
                            <CCol md={2}>코스</CCol>
                            <CCol>
                              <div className="badge-valign">
                              <span style={{ marginRight: '8px' }}>
                                <strong>
                                  {el.course_name}
                                  {' '}
                                  <CBadge color="primary">{el.course_id}</CBadge>
                                </strong>
                              </span>
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol md={2}>수강 기간</CCol>
                            <CCol>
                              <strong>{ el.course_duration}</strong>
                              일 동안 수강 가능
                            </CCol>
                          </CRow>
                          <hr />
                          <CRow><CCol><h5>사용 여부</h5></CCol></CRow>
                          <CRow>
                            <CCol md={2}>사용 여부</CCol>
                            <CCol>
                              {userCouponDetail.is_used ? (
                                <CBadge color="success">사용 완료</CBadge>
                              ) : (
                                <CBadge color="danger">미사용</CBadge>
                              )}
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol md={2}>사용 일시</CCol>
                            <CCol>
                              {el.used_at ? (moment(el.used_at).format(
                                'YYYY-MM-DD hh:mm:ss a',
                              )) : '-'}
                            </CCol>
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  );
                },
              )
            }
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  );

};

export default UserCouponDetailPage;
