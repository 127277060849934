import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

import PropTypes from 'prop-types';
import React from 'react';
import { cleanUpSearchParams } from '../../lib/utils';

class SubscriptionListSearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultUserId: props.user_id,
      defaultCourseId: props.course_id,

      prevPropsUserId: null,
      prevPropsCourseId: null,

      user_id: null,
      course_id: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.user_id !== prevState.prevPropsUserId ||
      nextProps.course_id !== prevState.prevPropsCourseId
    ) {
      return {
        prevPropsUserId: nextProps.user_id,
        prevPropsCourseId: nextProps.course_id,
        user_id: nextProps.user_id,
        course_id: nextProps.course_id,
      };
    }

    return null;
  }

  handleReset = () => {
    const { defaultUserId, defaultCourseId } = this.state;
    const { handleReset, handleSearchSubmit } = this.props;

    this.setState({
      user_id: defaultUserId,
      course_id: defaultCourseId,
    });

    if (typeof handleReset === 'function') {
      handleReset();
    } else if (typeof handleSearchSubmit === 'function') {
      handleSearchSubmit({
        user_id: defaultUserId,
        course_id: defaultCourseId,
      });
    }
  };

  render() {
    const { handleSearchSubmit, readOnlyCourseId, readOnlyUserId } = this.props;
    const { course_id, user_id } = this.state;
    return (
      <div>
        <Form>
          <FormGroup>
            <Label for="examplePassword">강의 ID</Label>
            <Input
              placeholder="강의 ID"
              onChange={(e) => {
                this.setState({
                  course_id: e.target.value,
                });
              }}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  const filtered = cleanUpSearchParams(this.state);
                  handleSearchSubmit(filtered);
                }
              }}
              readOnly={readOnlyCourseId}
              value={course_id || ''}
            />
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">사용자 ID</Label>
            <Input
              placeholder="사용자 ID"
              onChange={(e) => {
                this.setState({
                  user_id: e.target.value,
                });
              }}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  const filtered = cleanUpSearchParams(this.state);
                  handleSearchSubmit(filtered);
                }
              }}
              readOnly={readOnlyUserId}
              value={user_id || ''}
            />
          </FormGroup>

          <div className="text-right">
            <Button
              onClick={() => {
                const filtered = cleanUpSearchParams(this.state);
                handleSearchSubmit(filtered);
              }}
              style={{ marginRight: '8px' }}
            >
              찾아보기
            </Button>
            <Button onClick={this.handleReset}>검색 초기화</Button>
          </div>
        </Form>
      </div>
    );
  }
}
SubscriptionListSearchForm.defaultProps = {
  user_id: null,
  course_id: null,
  handleReset: null,
  handleSearchSubmit: null,
  readOnlyCourseId: false,
  readOnlyUserId: false,
};
SubscriptionListSearchForm.propTypes = {
  user_id: PropTypes.number,
  course_id: PropTypes.number,
  handleReset: PropTypes.func,
  handleSearchSubmit: PropTypes.func,
  readOnlyCourseId: PropTypes.bool,
  readOnlyUserId: PropTypes.bool,
};
export default SubscriptionListSearchForm;
