import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CInputRadio,
  CLabel,
  CRow,
} from '@coreui/react';
import { Controller, useForm } from 'react-hook-form';
import React from 'react';
import CIcon from '@coreui/icons-react';
import Datetime from 'react-datetime';
import chunk from 'chunk';
import moment from 'moment';

const VoucherForm = (props) => {
  const { mode, defaultValues, courseData, onSubmit } = props;
  const { handleSubmit, control, register, watch, errors } = useForm(
    {
      defaultValues: {
        ...defaultValues,
        exchange_course_id: defaultValues.exchange_course_id ? `${defaultValues.exchange_course_id}` : null,
        discount_course_id: defaultValues.discount_course_id ? `${defaultValues.discount_course_id}` : null,
        expires_at: defaultValues.expires_at ? moment(defaultValues.expires_at) : null,
      },
    },
  );

  const watchVoucherType = watch('voucher_type', defaultValues.voucher_type ? defaultValues.voucher_type : null);
  const COURSE_COLUMN_SIZE = 3;

  let courseDataChunked = [];
  try {
    let chunkSize = props.courseData.length / COURSE_COLUMN_SIZE;
    if (props.courseData.length % 3 !== 0) {
      chunkSize += 1;
    }
    courseDataChunked = chunk(courseData, chunkSize);
  } catch (e) {
  }
  const defaultFormHeader = (
    <CCardHeader>
      이용권
      {mode === 'edit' && <> 수정</>}
      <small> Form</small>
    </CCardHeader>
  );

  const validateFieldsAndSubmit = (data) => {
    const submitData = { ...data }

    if (data.voucher_type === 'exchange') {
      submitData.course_id = data.exchange_course_id
      submitData.course_duration = data.exchange_course_duration
    } else if (data.voucher_type === 'discount') {
      submitData.course_id = data.discount_course_id
      submitData.discount_percent = data.discount_percent
    }

    delete submitData.exchange_course_id
    delete submitData.exchange_course_duration
    delete submitData.discount_course_id

    return onSubmit(submitData)
  }

  const formHeader = props.formHeader ? props.formHeader : defaultFormHeader;

  return (
    <form onSubmit={handleSubmit(validateFieldsAndSubmit)}>
      <CCard>
        {formHeader}
        <CCardBody>
          {mode === 'edit' && (
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="name">ID</CLabel>
                  <CInput
                    id="id"
                    name="id"
                    innerRef={register({
                      required: 'Required',
                    })}
                    disabled
                  />
                </CFormGroup>
              </CCol>
            </CRow>
          )}
          <CRow>
            <CCol xs="12">
              <CFormGroup>
                <CLabel htmlFor="name">
                  사용자 ID <small>user_id</small>
                </CLabel>
                <CInput
                  id="user_id"
                  name="user_id"
                  className={{ 'is-invalid': errors.user_id }}
                  placeholder="ID"
                  innerRef={register({
                    required: 'Required',
                  })}
                />
                {errors.user_id && (
                  <div className="help-block">
                    사용자 ID 는 필수 입력항목입니다.
                  </div>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="12">
              <CFormGroup>
                <CLabel htmlFor="name">
                  이름 <small>name</small>
                </CLabel>
                <CInput
                  id="name"
                  name="name"
                  className={{ 'is-invalid': errors.name }}
                  placeholder="이용권 이름"
                  innerRef={register({
                    required: 'Required',
                  })}
                />
                {errors.name && (
                  <div className="help-block">
                    이용권 이름은 필수 입력항목입니다.
                  </div>
                )}
              </CFormGroup>
            </CCol>
          </CRow>

          <CCard className={{ 'is-invalid': errors.voucher_type }}>
            <CCardHeader>
              이용권 혜택
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <CRow>
                      <CCol>
                        <CFormGroup
                          variant="checkbox"
                          className="checkbox"
                        >
                          <CInputRadio
                            id="voucherTypeExchange"
                            name="voucher_type"
                            value="exchange"
                            innerRef={register({ required: true })}
                          />
                          <CLabel htmlFor="voucherTypeExchange">
                            강의 교환권 (결제 없이 강의 수강 가능)
                          </CLabel>
                        </CFormGroup>
                      </CCol>

                      <CCol>
                        <CFormGroup
                          variant="checkbox"
                          className="checkbox"
                        >
                          <CInputRadio
                            id="voucherTypeDiscount"
                            name="voucher_type"
                            value="discount"
                            innerRef={register({ required: true })}
                          />
                          <CLabel htmlFor="voucherTypeDiscount">
                            강의 할인권 (할인된 가격으로 강의 수강 가능)
                          </CLabel>
                        </CFormGroup>

                      </CCol>
                    </CRow>
                    {errors.voucher_type && (
                      <CRow>
                        <CCol>
                          <div className="help-block">
                            이용권 혜택은 필수 입력항목입니다.
                          </div>
                        </CCol>
                      </CRow>
                    )}


                  </CFormGroup>
                </CCol>
              </CRow>
              <div style={{ marginBottom: '-22px' }}>
                <CRow>
                  <CCol>
                    {
                      watchVoucherType === 'exchange' && (
                        <CCard className={{ 'is-invalid': errors.exchange_course_id }}>
                          <CCardBody>
                            <CRow>
                              <CCol xs="9">
                                <CFormGroup>
                                  <CLabel htmlFor="name">
                                    {errors.exchange_course_id ? '강의를 선택해주세요' : '강의'}
                                  </CLabel>
                                  <CRow>
                                    {
                                      courseDataChunked.map((items, idx) => (
                                        <CCol key={idx}>
                                          {items.map((el, i) => {
                                            const labelId = `lectureCheckbox-${el.id}`;
                                            return (
                                              <React.Fragment key={i}>
                                                <CFormGroup
                                                  key={el.id}
                                                  variant="checkbox"
                                                  className="checkbox"
                                                >
                                                  <CInputRadio
                                                    id={labelId}
                                                    name="exchange_course_id"
                                                    value={el.id}
                                                    innerRef={
                                                      idx * COURSE_COLUMN_SIZE + i + 1 === courseData.length
                                                        ? register({ required: true })
                                                        : register
                                                    }
                                                    disabled={el.state !== 'published'}
                                                  />
                                                  <CLabel
                                                    variant="checkbox"
                                                    className="form-check-label"
                                                    htmlFor={labelId}
                                                  >
                                                    <CBadge
                                                      color={
                                                        el.state === 'published'
                                                          ? 'primary'
                                                          : 'secondary'
                                                      }
                                                      style={{ marginRight: '4px' }}
                                                    >
                                                      {el.id}
                                                    </CBadge>
                                                    {el.name}
                                                  </CLabel>
                                                </CFormGroup>
                                              </React.Fragment>
                                            );
                                          })}
                                        </CCol>
                                      ))}
                                  </CRow>
                                </CFormGroup>
                              </CCol>
                              <CCol xs="3">
                                <CFormGroup>
                                  <CLabel htmlFor="name">
                                    수강기간 <small>course_duration</small>
                                  </CLabel>
                                  <CInputGroup>
                                    <CInput
                                      id="course_duration"
                                      name="exchange_course_duration"
                                      placeholder="60"
                                      className={{ 'is-invalid': errors.exchange_course_duration }}
                                      innerRef={register({
                                        required: 'Required',
                                        valueAsNumber: true,
                                      })}
                                    />
                                    <CInputGroupAppend>
                                      <CInputGroupText>
                                        일 <small style={{ paddingLeft: '4px' }}>days</small>
                                      </CInputGroupText>
                                    </CInputGroupAppend>
                                  </CInputGroup>
                                </CFormGroup>
                                {errors.course_duration && (
                                  <div className="help-block">
                                    수강기간은 필수 입력항목입니다.
                                  </div>
                                )}
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCard>
                      )
                    }
                    {
                      watchVoucherType === 'discount' && (
                        <CCard className={{ 'is-invalid': errors.discount_course_id }}>
                          <CCardBody>
                            <CRow>
                              <CCol xs="9">
                                <CFormGroup>
                                  <CLabel htmlFor="name">
                                    {errors.discount_course_id ? '강의를 선택해주세요' : '강의'}
                                  </CLabel>
                                  <CRow>
                                    {courseDataChunked.map((items, idx) => (
                                      <CCol key={idx}>
                                        {items.map((el, i) => {
                                          const labelId = `lectureCheckbox-${el.id}`;
                                          return (
                                            <React.Fragment key={i}>
                                              <CFormGroup
                                                key={el.id}
                                                variant="checkbox"
                                                className="checkbox"
                                              >
                                                <CInputRadio
                                                  id={labelId}
                                                  name="discount_course_id"
                                                  value={el.id}
                                                  innerRef={
                                                    idx * COURSE_COLUMN_SIZE + i + 1 === courseData.length
                                                      ? register({ required: true })
                                                      : register
                                                  }
                                                  disabled={el.state !== 'published'}
                                                />
                                                <CLabel
                                                  variant="checkbox"
                                                  className="form-check-label"
                                                  htmlFor={labelId}
                                                >
                                                  <CBadge
                                                    color={
                                                      el.state === 'published'
                                                        ? 'primary'
                                                        : 'secondary'
                                                    }
                                                    style={{ marginRight: '4px' }}
                                                  >
                                                    {el.id}
                                                  </CBadge>
                                                  {el.name}
                                                </CLabel>
                                              </CFormGroup>
                                            </React.Fragment>
                                          );
                                        })}
                                      </CCol>
                                    ))}
                                  </CRow>
                                </CFormGroup>
                              </CCol>
                              <CCol xs="3">
                                <CFormGroup>
                                  <CLabel htmlFor="discount_percent">
                                    할인율 <small>discount</small>
                                  </CLabel>
                                  <CInputGroup>
                                    <CInput
                                      id="discount_percent"
                                      name="discount_percent"
                                      placeholder="30"
                                      className={{ 'is-invalid': errors.discount_percent }}
                                      innerRef={register({
                                        required: 'Required',
                                        valueAsNumber: true,
                                      })}
                                    />
                                    <CInputGroupAppend>
                                      <CInputGroupText>
                                        퍼센트 <small style={{ paddingLeft: '4px' }}>%</small>
                                      </CInputGroupText>
                                    </CInputGroupAppend>
                                  </CInputGroup>
                                </CFormGroup>
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCard>
                      )
                    }
                  </CCol>
                </CRow>
              </div>
            </CCardBody>
          </CCard>

          <CCard>
            <CCardHeader>출처</CCardHeader>

            <CCardBody>
              <CRow>
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="name"> 종류 <small>src_type</small> </CLabel>
                    <CInput
                      id="src_type"
                      name="src_type"
                      placeholder=""
                      value={defaultValues.src_type}
                      readOnly={true}
                      innerRef={register({
                        required: 'Required',
                      })}
                    />
                  </CFormGroup>
                </CCol>
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="name"> 출처 ID <small>src_id</small> </CLabel>
                    <CInput
                      id="src_id"
                      name="src_id"
                      placeholder=""
                      value={defaultValues.src_id}
                      readOnly={true}
                      innerRef={register({
                        required: 'Required',
                      })}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>

          <CRow>
            <CCol xs="12">
              <CFormGroup>
                <CLabel htmlFor="name">
                  사용 만료 일시 <small>expire at</small>
                </CLabel>
                <Controller
                  name="expires_at"
                  control={control}
                  register={register({ required: true, })}
                  render={(props) => (
                    <Datetime
                      {...props}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm:ss"
                    />
                  )}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm:ss"
                />
              </CFormGroup>
            </CCol>
          </CRow>

        </CCardBody>
        <CCardFooter>
          <div style={{ padding: '4px 0 8px 0', textAlign: 'right' }}>
            <CButton type="submit" size="lg" color="primary">
              <CIcon name="cil-check" />
              {mode === 'edit' ? '이용권 수정' : '이용권 등록'}
            </CButton>{' '}
            <CButton type="reset" size="lg" color="danger">
              <CIcon name="cil-ban" /> Reset
            </CButton>
          </div>
        </CCardFooter>
      </CCard>
    </form>
  );
};

export default VoucherForm;
