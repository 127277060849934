import React, { Component } from 'react';
import {
  couponCodeFormatterFactory,
  couponFormatterFactory,
  dateTimeFormatterFactory,
} from '../common/formatter/formatter';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import BootstrapTable from 'react-bootstrap-table-next';
import CouponCodeListSearchForm from './common/CouponCodeListSearchForm';
import PaginationListAndSizePerPageDropdown from './common/PaginationListAndSizePerPageDropdown';
import _ from 'underscore';
import axios from 'axios';
import config from '../config';

let cancel;

export default class CouponCodeListTable extends Component {
  constructor(props) {
    super(props);

    const { couponId } = this.props;
    let search = {};
    if (couponId) {
      search = {
        coupon_id: couponId,
      };
    }

    this.state = {
      prevPropsPage: null,
      prevPropsSizePerPage: null,
      prevPropsSearch: null,

      //
      dataOrError: null,
      page: null,
      sizePerPage: 20,
      pagination: {},
      search: { ...search },
      expandRow: {
        renderer: (row) => (
          <div>
            <pre>{JSON.stringify(row, null, 2)}</pre>
          </div>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
          if (isAnyExpands) {
            return <i className="fa fa-minus-square" />;
          }
          return <i className="fa fa-plus-square" />;
        },
        expandColumnRenderer: ({ expanded }) => {
          if (expanded) {
            return <i className="fa fa-minus-square" />;
          }
          return <i className="fa fa-plus-square" />;
        },
      },
      columns: [
        {
          dataField: 'id',
          text: '번호',
          headerStyle: { width: '8em', textAlign: 'center' },
          classes: 'text-center',
        },
        {
          dataField: 'code',
          text: '쿠폰 코드',
          formatter: couponCodeFormatterFactory((row) => [row.id, row.code]),
          classes: 'text-truncate',
        },
        {
          dataField: 'coupon.name',
          text: '쿠폰',
          classes: 'text-truncate',
          formatter: couponFormatterFactory((row) => {
            try {
              return [row.coupon.id, row.coupon.name];
            } catch (e) {
              return [null, null];
            }
          }),
        },
        {
          dataField: 'couponp.start_at',
          text: '시작일시',
          formatter: dateTimeFormatterFactory((row) =>
            row.coupon ? row.coupon.start_at : null,
          ),
          classes: 'text-center',
          headerStyle: { width: '14em', textAlign: 'center' },
        },
        {
          dataField: 'coupon.expires_at',
          text: '만료일시',
          formatter: dateTimeFormatterFactory((row) =>
            row.coupon ? row.coupon.expires_at : null,
          ),
          classes: 'text-center',
          headerStyle: { width: '14em', textAlign: 'center' },
        },
        {
          dataField: 'created_at',
          text: '생성일시',
          formatter: dateTimeFormatterFactory((row) => row.created_at),
          classes: 'text-center',
          headerStyle: { width: '14em', textAlign: 'center' },
        },
      ],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('getDerivedStateFromProps');
    console.log({ nextProps, prevState });
    if (
      nextProps.page !== prevState.prevPropsPage ||
      nextProps.sizePerPage !== prevState.prevPropsSizePerPage ||
      !_.isEqual(nextProps.search, prevState.prevPropsSearch)
    ) {
      if (typeof cancel === 'function') {
        cancel();
      }

      return {
        prevPropsPage: nextProps.page,
        prevPropsSizePerPage: nextProps.sizePerPage,
        prevPropsSearch: nextProps.search,
        //
        page: nextProps.page ? nextProps.page : 1,
        sizePerPage: nextProps.sizePerPage ? nextProps.sizePerPage : 20,
        search: { ...nextProps.search },
        dataOrError: null,
      };
    }

    return null;
  }

  componentDidMount() {
    try {
      const { page, sizePerPage, search } = this.state;
      this.loadItemList(page, sizePerPage, search);
    } catch (e) {
      console.log(e);
    }
  }

  reloadItemList = () => {
    const { page, sizePerPage, search } = this.state;
    this.loadItemList(page, sizePerPage, search);
  };

  loadItemList = async (page, sizePerPage, search) => {
    try {
      const res = await axios.get(
        `${config.API_BASE}/admin/coupons/coupon-codes`,
        {
          params: {
            page,
            per_page: sizePerPage,
            ...search,
          },
        },
      );

      if (!res) {
        return;
      }

      this.setState({
        dataOrError: res.data.data,
        pagination: res.data.pagination,
      });
    } catch (e) {
      console.log(e);

      this.setState({
        dataOrError: [],
        pagination: {},
      });
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.dataOrError === null) {
      const { page, sizePerPage, search } = this.state;
      await this.loadItemList(page, sizePerPage, search);
    }
  };

  handleTableChange = async (type, { page, sizePerPage }) => {
    this.setState(() => ({
      dataOrError: null,
      page,
      sizePerPage,
    }));
  };

  handleSearchSubmit = (params) => {
    console.log('handleSearchSubmit', params);
    this.setState({
      search: {
        ...params,
      },
      dataOrError: null,
      page: 1,
    });
  };

  render() {
    const {
      dataOrError,
      sizePerPage,
      page,
      pagination: { total_count: totalSize },
      search,
      columns,
      expandRow,
    } = this.state;

    console.log(this.state);

    const options = {
      page,
      sizePerPage,
      custom: true,
      totalSize,
    };
    console.log(options);

    return (
      <div>
        <div>
          <CouponCodeListSearchForm
            handleSearchSubmit={this.handleSearchSubmit}
            readOnlyCouponId={this.props.readOnlyCouponId}
            {...search}
          />
          <hr />
        </div>

        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              {!this.props.hideTopPaginationArea && (
                <PaginationListAndSizePerPageDropdown
                  paginationProps={paginationProps}
                />
              )}

              <BootstrapTable
                remote
                keyField="id"
                data={dataOrError || []}
                columns={columns}
                onTableChange={this.handleTableChange}
                expandRow={expandRow}
                {...paginationTableProps}
              />

              {!this.props.hideBottomPaginationArea && (
                <PaginationListAndSizePerPageDropdown
                  paginationProps={paginationProps}
                />
              )}
            </div>
          )}
        </PaginationProvider>
      </div>
    );
  }
}
