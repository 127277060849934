import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';

import BlockUi from 'react-block-ui';
import { CouponDetail } from '../../components/CouponDetail';
import { CouponForm } from '../../components/CouponForm';
import axios from 'axios';
import config from '../../config';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import CouponNav from '../../components/CouponNav';

const CouponDetailPage = (props) => {
  const { id: couponId } = props.match.params;
  const { history } = props;

  const [couponDetail, setCouponDetail] = useState({});
  const [couponStats, setCouponStats] = useState({});
  const [viewMode, setViewMode] = useState('detail');
  const [courseData, setCourseData] = useState([]);
  const [blockingCouponEdit, setBlockingCouponEdit] = useState(false);
  const [
    blockingGenerateCouponCodeForm,
    setBlockingGenerateCouponCodeForm,
  ] = useState(false);

  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  const dataTable = useRef();

  const handleCouponEditSubmit = (value) => {
    setBlockingCouponEdit(true);
    const endpoint = `${config.API_BASE}/admin/coupons/coupons/${couponId}`;

    ['expires_at', 'start_at', 'use_expires_at'].map((key, idx) => {
      if (moment.isMoment(value[key])) {
        value[key] = value[key].format();
      }
    });

    ['course_duration', 'max_use_count', 'use_expire_in_days'].map(
      (key, idx) => {
        if (typeof value[key] === 'string') {
          value[key] = parseInt(value[key]);
        }
      },
    );

    value.course_ids = value.course_ids.map((courseId, idx) =>
      parseInt(courseId),
    );

    value.is_active = value.is_active === 'true';

    axios
      .post(endpoint, value)
      .then((res) => {
        console.log(res);

        const couponData = res.data.data

        if (couponData.voucher_type === 'exchange') {
          couponData.exchange_course_ids = couponData.course_ids
          couponData.exchange_course_duration = couponData.course_duration
        } else if (couponData.voucher_type === 'discount') {
          couponData.discount_course_ids = couponData.course_ids
          couponData.discount_percent = couponData.discount_percent
        }

        setCouponDetail(couponData);
        setBlockingCouponEdit(false);
        setViewMode('detail');
      })
      .catch((err) => {
        console.log(err);
        setBlockingCouponEdit(false);
      });
  };

  const showCouponDeleteConfirmModal = () => {
    setDeleteConfirmModal(true);
  };

  const handleCouponDelete = () => {
    const endpoint = `${config.API_BASE}/admin/coupons/coupons/${couponId}`;
    axios
      .delete(endpoint)
      .then((res) => {
        history.push('/coupons/coupons/list');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCouponDetail = async () => {
    const endpoint = `${config.API_BASE}/admin/coupons/coupons/${couponId}`;

    try {
      const res = await axios.get(endpoint);
      const couponData = res.data.data

      if (couponData.voucher_type === 'exchange') {
        couponData.exchange_course_ids = couponData.course_ids
        couponData.exchange_course_duration = couponData.course_duration
      } else if (couponData.voucher_type === 'discount') {
        couponData.discount_course_ids = couponData.course_ids
        couponData.discount_percent = couponData.discount_percent
      }

      setCouponDetail(couponData);
    } catch (e) {
      console.log(e);
      setCouponDetail(null);
    }
  };

  const getCouponStats = async () => {
    const endpoint = `${config.API_BASE}/admin/coupons/coupons/${couponId}/stats`;

    try {
      const res = await axios.get(endpoint);
      setCouponStats(res.data.data);
    } catch (e) {
      console.log(e);
      setCouponStats(null);
    }
  };

  const getCourseData = () => {
    const endpoint = `${config.API_BASE}/admin/lectures/courses/ids`;
    axios
      .get(endpoint)
      .then((res) => {
        setCourseData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCouponDetail();
    getCouponStats();
    getCourseData();
  }, []);

  return (
    <>
      <>
        <CModal
          show={deleteConfirmModal}
          onClose={() => {
            setDeleteConfirmModal(false);
          }}
        >
          <CModalHeader closeButton>쿠폰 삭제 확인</CModalHeader>
          <CModalBody>
            쿠폰: <strong>{couponDetail.name}</strong>{' '}
            <CBadge color="primary">{couponDetail.id}</CBadge>을
            삭제하시겠습니까?
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" onClick={handleCouponDelete}>
              삭제
            </CButton>{' '}
            <CButton
              color="secondary"
              onClick={() => {
                setDeleteConfirmModal(false);
              }}
            >
              취소
            </CButton>
          </CModalFooter>
        </CModal>
      </>

      <CouponNav couponId={couponId} couponStats={couponStats} />

      <CRow>
        <CCol>
          {viewMode === 'detail' && (
            <>
              <CCard>
                <CCardHeader>
                  <div className="card-header-actions">
                    <CButton
                      color="primary"
                      size="sm"
                      variant="outline"
                      style={{ marginRight: '6px' }}
                      onClick={() => setViewMode('edit')}
                    >
                      수정하기
                    </CButton>
                    <CButton
                      color="danger"
                      size="sm"
                      variant="outline"
                      onClick={showCouponDeleteConfirmModal}
                    >
                      삭제하기
                    </CButton>
                  </div>
                  쿠폰 상세
                </CCardHeader>
                <CCardBody>
                  <CouponDetail
                    courseData={courseData}
                    {...couponDetail}
                  ></CouponDetail>
                </CCardBody>
              </CCard>
              <CCard>
                <CCardHeader>쿠폰 사용 현황</CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={2}>등록된 쿠폰 코드 갯수</CCol>
                    <CCol>
                      <strong>{couponStats.coupon_count}</strong> 개
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={2}>쿠폰 코드를 등록한 회원 수</CCol>
                    <CCol md={4}>
                      <strong>{couponStats.registered_user_count}</strong> 명
                    </CCol>
                    <CCol md={2}>쿠폰 코드를 사용한 회원 수</CCol>
                    <CCol md={4}>
                      <strong>{couponStats.used_user_count}</strong> 명
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </>
          )}
          {viewMode === 'edit' && (
            <BlockUi
              tag="div"
              blocking={blockingCouponEdit}
              message="쿠폰을 수정하고 있습니다."
            >
              <CouponForm
                mode="edit"
                defaultValues={couponDetail}
                courseData={courseData}
                onSubmit={handleCouponEditSubmit}
                formHeader={
                  <CCardHeader>
                    <div className="card-header-actions">
                      <CButton
                        color="primary"
                        size="sm"
                        variant="outline"
                        onClick={() => setViewMode('detail')}
                      >
                        닫기
                      </CButton>
                    </div>
                    쿠폰 수정
                  </CCardHeader>
                }
              />
            </BlockUi>
          )}
        </CCol>
      </CRow>
    </>
  );
};

export default withRouter(CouponDetailPage);
