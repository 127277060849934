import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react';
import React, { Component } from 'react';
import {
  booleanFormatterFactory,
  couponFormatterFactory,
  courseFormatterFactory,
  dateTimeFormatterFactory,
  userIdFormatterFactory,
} from '../common/formatter/formatter';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from 'reactstrap';
import { CBadge } from '@coreui/react';
import { Link } from 'react-router-dom';
import PaginationListAndSizePerPageDropdown from './common/PaginationListAndSizePerPageDropdown';
import VoucherListSearchForm from './common/VoucherListSearchForm';
import _ from 'underscore';
import axios from 'axios';
import config from '../config';
import moment from 'moment';

let cancel;

class VoucherListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duplicateConfirmModal: false,
      duplicateTargetCouponId: null,

      prevPropsPage: null,
      prevPropsSizePerPage: null,
      prevPropsSearch: null,

      //
      dataOrError: null,
      page: null,
      sizePerPage: 20,
      pagination: {},
      search: {},
      expandRow: {
        renderer: (row) => (
          <div>
            <pre>{JSON.stringify(row, null, 2)}</pre>
          </div>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
          if (isAnyExpands) {
            return <i className="fa fa-minus-square" />;
          }
          return <i className="fa fa-plus-square" />;
        },
        expandColumnRenderer: ({ expanded }) => {
          if (expanded) {
            return <i className="fa fa-minus-square" />;
          }
          return <i className="fa fa-plus-square" />;
        },
      },
      columns: [
        {
          dataField: 'id',
          text: '번호',
          headerStyle: { width: '6em', textAlign: 'center' },
          classes: 'text-center',
        },
        {
          dataField: 'user_id',
          text: '사용자 ID',
          headerStyle: {width: '6em', textAlign: 'center' },
          classes: 'text-center',
          formatter: userIdFormatterFactory((row) => row.user_id),
        },
        {
          dataField: 'name',
          text: '이용권',
          headerStyle: {textAlign: 'center' },
          classes: 'text-center',
          // formatter: userIdFormatterFactory((row) => row.user_id),
        },
        // {
        //   dataField: 'voucher_type',
        //   text: '이용권 종류',
        //   headerStyle: {width: '8em', textAlign: 'center' },
        //   classes: 'text-center',
        //   formatter: (col, row) => {
        //     if (col === 'exchange') {
        //       return (
        //         <CBadge color="primary">
        //           강의 교환권
        //         </CBadge>
        //       )
        //     }
        //     if (col === 'discount') {
        //       return (
        //         <CBadge color="primary">
        //           강의 할인권
        //         </CBadge>
        //       )
        //     }
        //
        //     return (
        //       <>
        //         -
        //       </>
        //     )
        //   },
        // },
        {
          dataField: 'benefits',
          text: '혜택',
          headerStyle: {textAlign: 'center' },
          isDummyField: true,
          // classes: 'text-truncate',
          // formatter: userIdFormatterFactory((row) => row.user_id),
          formatter: (col, row) => {
            const voucherType = row.voucher_type
            const benefit = row.benefit

            if (voucherType === 'exchange') {
              return (
                <div>
                  <CBadge color="success">
                    강의 교환권
                  </CBadge>
                  <hr style={{margin: '0.5rem 0'}} />
                  <div className='text-truncate badge-valign'>
                    <Link to={`/courses/${benefit.course_id}`}>
                      <CBadge color='primary'>{ benefit.course_id }</CBadge>{' '}
                      { row.course_name }
                    </Link>
                  </div>
                  <div>
                    수강기간: <strong>{ benefit.course_duration }</strong>일
                  </div>
                </div>
              )
            }
            if (voucherType === 'discount') {
              return (
                <div>
                  <CBadge color="info">
                    강의 할인권
                  </CBadge>
                  <hr style={{margin: '0.5rem 0'}} />
                  <div className='text-truncate badge-valign'>
                    <Link to={`/courses/${benefit.course_id}`}>
                      <CBadge color='primary'>{ benefit.course_id }</CBadge>{' '}
                      { row.course_name }
                    </Link>
                  </div>
                  <div>
                    할인율: <strong>{ benefit.discount_percent }</strong>%
                  </div>
                </div>
              )
            }
          }
        },

        // {
        //   dataField: 'course_id',
        //   text: '강의',
        //   classes: 'text-truncate',
        //   formatter: courseFormatterFactory((row) => [
        //     row.course_id,
        //     row.course_name,
        //   ]),
        // },
        // {
        //   dataField: 'course_duration',
        //   text: '수강 기간',
        //   classes: 'text-truncate text-center',
        //   headerStyle: { width: '8em', textAlign: 'center' },
        // },
        {
          dataField: 'is_used',
          text: '사용 여부',
          formatter: booleanFormatterFactory((row) => row.is_used),
          classes: 'text-center',
          headerStyle: { width: '6em', textAlign: 'center' },
        },

        {
          dataField: 'name',
          text: '출처',
          headerStyle: { width: '12em', textAlign: 'center' },
          classes: 'text-truncate',
          formatter: (cell, row) => {
            // const source = row.source;
            const { id, type } = row.source;
            if (type === 'user_coupon') {
              return (
                <div className="badge-valign">
                  <Link to={`/coupons/user-coupons/${id}`}>
                    <CBadge color="primary">{id}</CBadge>{' '}
                    등록쿠폰
                  </Link>
                </div>
              );
            } else {
              return (
                <div className="badge-valign">
                  기타
                </div>
              );

            }
          },
        },

        {
          dataField: 'used_at',
          text: '사용 일시',
          formatter: dateTimeFormatterFactory((row) => row.used_at),
          classes: 'text-center',
          headerStyle: { width: '12em', textAlign: 'center' },
        },
        {
          dataField: 'created_at',
          text: '생성 / 만료 일시',
          headerStyle: { width: '14em', textAlign: 'center' },
          classes: 'text-center',
          style: { textAlign: 'left' },
          formatter: (cell, row) => {
            const createdAt = moment(row.created_at);
            const expiresdAt = moment(row.expires_at);
            const format = 'YYYY-MM-DD HH:mm';
            return (
              <>
                {
                  createdAt.isValid() && (
                    <div>
                      <CBadge color="primary" style={{ marginRight: '4px' }}>생성</CBadge>
                      <code style={{ color: '#3c4b64' }}>
                        {createdAt.format(format)}
                      </code>
                    </div>
                  )
                }
                {
                  expiresdAt.isValid() && (
                    <div>
                      <CBadge color="primary" style={{ marginRight: '4px' }}>만료</CBadge>
                      <code style={{ color: '#3c4b64' }}>
                        {expiresdAt.format(format)}
                      </code>
                    </div>
                  )
                }
              </>
            );
          },
        },
      ],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.page !== prevState.prevPropsPage ||
      nextProps.sizePerPage !== prevState.prevPropsSizePerPage ||
      !_.isEqual(nextProps.search, prevState.prevPropsSearch)
    ) {
      if (typeof cancel === 'function') {
        cancel();
      }

      return {
        prevPropsPage: nextProps.page,
        prevPropsSizePerPage: nextProps.sizePerPage,
        prevPropsSearch: nextProps.search,
        //
        page: nextProps.page ? nextProps.page : 1,
        sizePerPage: nextProps.sizePerPage ? nextProps.sizePerPage : 20,
        search: { ...nextProps.search },
        dataOrError: null,
      };
    }

    return null;
  }

  componentDidMount() {
    try {
      const { page, sizePerPage, search } = this.state;
      this.loadItemList(page, sizePerPage, search);
    } catch (e) {
      console.log(e);
    }
  }

  loadItemList = async (page, sizePerPage, search) => {
    try {
      const res = await axios.get(`${config.API_BASE}/admin/coupons/vouchers`, {
        params: {
          page,
          per_page: sizePerPage,
          ...search,
        },
      });

      if (!res) {
        return;
      }

      this.setState({
        dataOrError: res.data.data,
        pagination: res.data.pagination,
      });
    } catch (e) {
      console.log(e);

      this.setState({
        dataOrError: [],
        pagination: {},
      });
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.dataOrError === null) {
      const { page, sizePerPage, search } = this.state;
      await this.loadItemList(page, sizePerPage, search);
    }
  };

  handleTableChange = async (type, { page, sizePerPage }) => {
    this.setState(() => ({
      dataOrError: null,
      page,
      sizePerPage,
    }));
  };

  handleSearchSubmit = (params) => {
    this.setState({
      search: {
        ...params,
      },
      dataOrError: null,
      page: 1,
    });
  };

  reloadItemList = () => {
    const { page, sizePerPage, search } = this.state;
    this.loadItemList(page, sizePerPage, search);
  };

  render() {
    const {
      dataOrError,
      sizePerPage,
      page,
      pagination: { total_count: totalSize },
      search,
      columns,
      expandRow,
      duplicateConfirmModal,
      duplicateTargetCouponId,
    } = this.state;

    const options = {
      page,
      sizePerPage,
      custom: true,
      totalSize,
    };

    return (
      <div>
        <div>
          <VoucherListSearchForm
            handleSearchSubmit={this.handleSearchSubmit}
            {...search}
          />
          <hr />
        </div>

        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              {!this.props.hideTopPaginationArea && (
                <PaginationListAndSizePerPageDropdown
                  paginationProps={paginationProps}
                />
              )}

              {/* <pre>{JSON.stringify(dataOrError, null, 2)}</pre> */}

              <BootstrapTable
                remote
                keyField="id"
                data={dataOrError || []}
                columns={columns}
                onTableChange={this.handleTableChange}
                expandRow={expandRow}
                {...paginationTableProps}
              />

              {!this.props.hideBottomPaginationArea && (
                <PaginationListAndSizePerPageDropdown
                  paginationProps={paginationProps}
                />
              )}
            </div>
          )}
        </PaginationProvider>
      </div>
    );
  }
}

export default VoucherListTable;
