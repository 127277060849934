import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import CouponListTable from '../../components/CouponListTable';
import CouponListNav from '../../components/CouponListNav';

const CouponListPage = () => (
  <div className="animated fadeIn">
    <CouponListNav />

    <Row>
      <Col>
        <Card>
          <CardHeader>
            <i className="fa fa-align-justify" /> 쿠폰 목록
          </CardHeader>
          <CardBody>
            <CouponListTable />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

export default CouponListPage;
