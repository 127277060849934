import CouponActivityListPage from './CouponActivityListPage';
import CouponCodeDetailPage from './CouponCodeDetailPage';
import CouponCodeList from './CouponCodeList';
import CouponCouponCodeListPage from './CouponCouponCodeListPage';
import { CouponCreate } from './CouponCreate';
import { CouponDashboard } from './CouponDashboard';
import CouponDetailPage from './CouponDetailPage';
import CouponListPage from './CouponListPage';
import CouponUserCouponListPage from './CouponUserCouponListPage';
import UserCouponDetailPage from './UserCouponDetailPage';
import UserCouponListPage from './UserCouponListPage';
import VoucherCreate from './VoucherCreate';
import VoucherListPage from './VoucherListPage';

export const routes = [
  {
    path: '/coupons',
    name: '쿠폰',
    exact: true,
    component: CouponDashboard,
  },
  {
    path: '/coupons/coupons/create',
    name: '쿠폰 생성',
    exact: true,
    component: CouponCreate,
  },
  {
    path: '/coupons/coupons/list',
    name: '쿠폰 목록',
    exact: true,
    component: CouponListPage,
  },
  {
    path: '/coupons/coupons/:id',
    name: '쿠폰 상세',
    exact: true,
    component: CouponDetailPage,
  },
  {
    path: '/coupons/coupons/:id/coupon-codes',
    name: '쿠폰 코드',
    exact: true,
    component: CouponCouponCodeListPage,
  },
  {
    path: '/coupons/coupons/:id/user-coupons',
    name: '등록 쿠폰',
    exact: true,
    component: CouponUserCouponListPage,
  },
  {
    path: '/coupons/coupon-codes/list',
    name: '쿠폰 목록',
    exact: true,
    component: CouponCodeList,
  },
  {
    path: '/coupons/coupon-codes/:couponCodeId',
    name: '쿠폰 코드 상세',
    exact: true,
    component: CouponCodeDetailPage,
  },
  {
    path: '/coupons/user-coupons/list',
    name: '등록 쿠폰',
    exact: true,
    component: UserCouponListPage,
  },
  {
    path: '/coupons/user-coupons/:userCouponId',
    name: '등록 쿠폰 상세',
    exact: true,
    component: UserCouponDetailPage,
  },
  {
    path: '/coupons/coupon-activities/list',
    name: '쿠폰 로그',
    exact: true,
    component: CouponActivityListPage,
  },
  {
    path: '/coupons/vouchers/create',
    name: '이용권 생성',
    exact: true,
    component: VoucherCreate,
  },
  {
    path: '/coupons/vouchers/list',
    name: '이용권 목록',
    exact: true,
    component: VoucherListPage,
  },
];
