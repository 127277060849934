import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';

import React  from 'react';
import SubscriptionListTable from '../../components/SubscriptionListTable';

export const SubscriptionList = () => {

  return (
    <>
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>사용자 수강 목록</CCardHeader>
            <CCardBody>
              <SubscriptionListTable />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
