import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CInputRadio,
  CLabel,
  CRow,
} from '@coreui/react';
import { Controller, useForm } from 'react-hook-form';

import CIcon from '@coreui/icons-react';
import Datetime from 'react-datetime';
import React from 'react';
import chunk from 'chunk';
import classNames from 'classnames';
import moment from 'moment';

export const CouponForm = (props) => {
  const COURSE_COLUMN_SIZE = 3;
  const { mode, defaultValues: _default, courseData, onSubmit } = props;
  const defaultValues = _default ? _default : {};
  const { handleSubmit, control, register, errors, watch, setError } = useForm(
    {
      defaultValues: {
        ...defaultValues,
        is_active: mode !== 'edit' ? 'false' : (defaultValues.is_active ? 'true' : 'false'),
        use_expire_type: mode !== 'edit' ? 'none' : defaultValues.use_expire_type,
        start_at: mode !== 'edit' ? null : moment(defaultValues.start_at),
        expires_at: mode !== 'edit' ? null : moment(defaultValues.expires_at),
        exchange_course_id:
          defaultValues.exchange_course_ids ?
            defaultValues.exchange_course_ids.map((el) => `${el}`) : [],
        discount_course_id:
          defaultValues.discount_course_ids ?
            defaultValues.discount_course_ids.map((el) => `${el}`) : [],
        use_expires_at:
          defaultValues.use_expires_at ?
            moment(defaultValues.use_expires_at) : null,
      },
    },
  );

  const watchVoucherType = watch('voucher_type', defaultValues.voucher_type ? defaultValues.voucher_type : null);
  const watchUseExpireType = watch('use_expire_type', defaultValues.use ? defaultValues.use_expire_type : 'none');

  let courseDataChunked = [];
  try {
    let chunkSize = courseData.length / COURSE_COLUMN_SIZE;
    if (courseData.length % 3 !== 0) {
      chunkSize += 1;
    }
    courseDataChunked = chunk(courseData, chunkSize);
  } catch (e) {
    // console.log(e);
  }

  const defaultFormHeader = (
    <CCardHeader>
      쿠폰
      {mode === 'edit' && <> 수정</>}
      <small> Form</small>
    </CCardHeader>
  );

  const validateFieldsAndSubmit = (data) => {
    let hasError = false;
    const submitData = { ...data }

    if (!data.start_at) {
      setError('start_at', { type: 'required' });
      hasError = true;
    }
    if (!data.expires_at) {
      setError('expires_at', { type: 'required' });
      hasError = true;
    }
    if (data.use_expire_type === 'datetime' && !data.use_expires_at) {
      setError('use_expires_at', { type: 'required' });
      hasError = true;
    }

    if (hasError) {
      return false;
    }

    if (data.voucher_type === 'exchange') {
      submitData.course_ids = data.exchange_course_id.map(el => parseInt(el))
      submitData.course_duration = parseInt(data.exchange_course_duration)
    } else if (data.voucher_type === 'discount') {
      submitData.course_ids = data.discount_course_id.map(el => parseInt(el))
      submitData.discount_percent = parseInt(data.discount_percent)
    }

    delete submitData.exchange_course_id
    delete submitData.exchange_course_duration
    delete submitData.discount_course_id

    return onSubmit(submitData);

  };

  const formHeader = props.formHeader ? props.formHeader : defaultFormHeader;

  return (
    <form onSubmit={handleSubmit(validateFieldsAndSubmit)}>
      <CCard>
        {formHeader}
        <CCardBody>
          {mode === 'edit' && (
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="name">ID</CLabel>
                  <CInput
                    id="id"
                    name="id"
                    innerRef={register({ required: 'Required' })}
                    readOnly={true}
                  />
                </CFormGroup>
              </CCol>
            </CRow>
          )}
          <CRow>
            <CCol xs="12">
              <CFormGroup>
                <CLabel htmlFor="name">
                  쿠폰 이름 <small>name</small>
                </CLabel>
                <CInput
                  id="name"
                  name="name"
                  className={{ 'is-invalid': errors.name }}
                  placeholder="쿠폰 이름"
                  innerRef={register({ required: true })}
                />
                {errors.name && (
                  <div className="help-block">
                    쿠폰 이름은 필수 입력항목입니다.
                  </div>
                )}
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12">
              <CFormGroup>
                <CLabel htmlFor="description">
                  쿠폰 설명 <small>description</small>
                </CLabel>
                <CInput
                  name="description"
                  id="description"
                  className={{ 'is-invalid': errors.name }}
                  placeholder="쿠폰 설명"
                  defaultValue={defaultValues.description}
                  innerRef={register({ required: 'Required' })}
                />
                {errors.name && (
                  <div className="help-block">
                    쿠폰 설명은 필수 입력항목입니다.
                  </div>
                )}
              </CFormGroup>
            </CCol>
          </CRow>

          <CCard className={{ 'is-invalid': errors.voucher_type }}>
            <CCardHeader>쿠폰 혜택</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <CRow>
                      <CCol>
                        <CFormGroup
                          variant="checkbox"
                          className="checkbox"
                        >
                          <CInputRadio
                            id="voucherTypeExchange"
                            name="voucher_type"
                            value="exchange"
                            innerRef={register({ required: true })}
                          />
                          <CLabel htmlFor="voucherTypeExchange">
                            강의 교환권 (결제 없이 강의 수강 가능)
                          </CLabel>
                        </CFormGroup>
                      </CCol>

                      <CCol>
                        <CFormGroup
                          variant="checkbox"
                          className="checkbox"
                        >
                          <CInputRadio
                            id="voucherTypeDiscount"
                            name="voucher_type"
                            value="discount"
                            innerRef={register({ required: true })}
                          />
                          <CLabel htmlFor="voucherTypeDiscount">
                            강의 할인권 (할인된 가격으로 강의 수강 가능)
                          </CLabel>
                        </CFormGroup>
                      </CCol>
                    </CRow>
                    {errors.voucher_type && (
                      <CRow>
                        <CCol>
                          <div className="help-block">
                            이용권 혜택은 필수 입력항목입니다.
                          </div>
                        </CCol>
                      </CRow>
                    )}
                  </CFormGroup>
                </CCol>
              </CRow>

              <div style={{ marginBottom: '-22px' }}>
                <CRow>
                  <CCol>
                    {
                      watchVoucherType === 'exchange' && (
                        <CCard className={{ 'is-invalid': errors.exchange_course_id }}>
                          <CCardBody>
                            <CRow>
                              <CCol xs="9">
                                <CFormGroup>
                                  <CLabel htmlFor="name">
                                    {errors.exchange_course_id ? '강의를 선택해주세요' : '강의'}
                                  </CLabel>
                                  <CRow>
                                    {
                                      courseDataChunked.map((items, idx) => (
                                        <CCol key={idx}>
                                          {items.map((el, i) => {
                                            const labelId = `lectureCheckbox-${el.id}`;
                                            return (
                                              <React.Fragment key={i}>
                                                <CFormGroup
                                                  key={el.id}
                                                  variant="checkbox"
                                                  className="checkbox"
                                                >
                                                  <CInputCheckbox
                                                    id={labelId}
                                                    name="exchange_course_id"
                                                    value={el.id}
                                                    innerRef={
                                                      idx * COURSE_COLUMN_SIZE + i + 1 === courseData.length
                                                        ? register({ required: true })
                                                        : register
                                                    }
                                                    disabled={el.state !== 'published'}
                                                  />
                                                  <CLabel
                                                    variant="checkbox"
                                                    className="form-check-label"
                                                    htmlFor={labelId}
                                                  >
                                                    <CBadge
                                                      color={
                                                        el.state === 'published'
                                                          ? 'primary'
                                                          : 'secondary'
                                                      }
                                                      style={{ marginRight: '4px' }}
                                                    >
                                                      {el.id}
                                                    </CBadge>
                                                    {el.name}
                                                  </CLabel>
                                                </CFormGroup>
                                              </React.Fragment>
                                            );
                                          })}
                                        </CCol>
                                      ))}
                                  </CRow>
                                </CFormGroup>
                              </CCol>
                              <CCol xs="3">
                                <CFormGroup>
                                  <CLabel htmlFor="name">
                                    수강기간 <small>course_duration</small>
                                  </CLabel>
                                  <CInputGroup
                                    className={{ 'is-invalid': errors.exchange_course_duration }}
                                  >
                                    <CInput
                                      id="course_duration"
                                      name="exchange_course_duration"
                                      placeholder="60"
                                      innerRef={register({
                                        required: 'Required',
                                        valueAsNumber: true,
                                      })}
                                    />
                                    <CInputGroupAppend>
                                      <CInputGroupText>
                                        일 <small style={{ paddingLeft: '4px' }}>days</small>
                                      </CInputGroupText>
                                    </CInputGroupAppend>
                                  </CInputGroup>
                                </CFormGroup>
                                {errors.course_duration && (
                                  <div className="help-block">
                                    수강기간은 필수 입력항목입니다.
                                  </div>
                                )}
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCard>
                      )
                    }
                    {
                      watchVoucherType === 'discount' && (
                        <CCard className={{ 'is-invalid': errors.discount_course_id }}>
                          <CCardBody>
                            <CRow>
                              <CCol xs="9">
                                <CFormGroup>
                                  <CLabel htmlFor="name">
                                    {errors.discount_course_id ? '강의를 선택해주세요' : '강의'}
                                  </CLabel>
                                  <CRow>
                                    {courseDataChunked.map((items, idx) => (
                                      <CCol key={idx}>
                                        {items.map((el, i) => {
                                          const labelId = `lectureCheckbox-${el.id}`;
                                          return (
                                            <React.Fragment key={i}>
                                              <CFormGroup
                                                key={el.id}
                                                variant="checkbox"
                                                className="checkbox"
                                              >
                                                <CInputCheckbox
                                                  id={labelId}
                                                  name="discount_course_id"
                                                  value={el.id}
                                                  innerRef={
                                                    idx * COURSE_COLUMN_SIZE + i + 1 === courseData.length
                                                      ? register({ required: true })
                                                      : register
                                                  }
                                                  disabled={el.state !== 'published'}
                                                />
                                                <CLabel
                                                  variant="checkbox"
                                                  className="form-check-label"
                                                  htmlFor={labelId}
                                                >
                                                  <CBadge
                                                    color={
                                                      el.state === 'published'
                                                        ? 'primary'
                                                        : 'secondary'
                                                    }
                                                    style={{ marginRight: '4px' }}
                                                  >
                                                    {el.id}
                                                  </CBadge>
                                                  {el.name}
                                                </CLabel>
                                              </CFormGroup>
                                            </React.Fragment>
                                          );
                                        })}
                                      </CCol>
                                    ))}
                                  </CRow>
                                </CFormGroup>
                              </CCol>
                              <CCol xs="3">
                                <CFormGroup>
                                  <CLabel htmlFor="discount_percent">
                                    할인율 <small>discount</small>
                                  </CLabel>
                                  <CInputGroup
                                    className={{ 'is-invalid': errors.discount_percent }}
                                  >
                                    <CInput
                                      id="discount_percent"
                                      name="discount_percent"
                                      placeholder="30"
                                      innerRef={register({
                                        required: 'Required',
                                        valueAsNumber: true,
                                      })}
                                    />
                                    <CInputGroupAppend>
                                      <CInputGroupText>
                                        퍼센트 <small style={{ paddingLeft: '4px' }}>%</small>
                                      </CInputGroupText>
                                    </CInputGroupAppend>
                                  </CInputGroup>
                                </CFormGroup>
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCard>
                      )
                    }
                  </CCol>
                </CRow>
              </div>

            </CCardBody>
          </CCard>

          <CRow>
            <CCol xs="12">
              <CFormGroup>
                <CLabel htmlFor="name">
                  쿠폰 코드 당 최대 등록 가능 횟수 <small>max use count</small>
                </CLabel>
                <CInputGroup>
                  <CInput
                    id="max_use_count"
                    name="max_use_count"
                    className={{ 'is-invalid': errors.max_use_count }}
                    placeholder="1"
                    defaultValue={defaultValues.max_use_count}
                    innerRef={register({ required: 'Required', })}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>회 / 쿠폰코드</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12">
              <CCard className={{ 'is-invalid': errors.is_active }}>
                <CCardHeader>
                  활성 여부 <small>is active</small>
                </CCardHeader>
                <CCardBody>
                  <div style={{ marginBottom: '-22px' }}>
                    <CFormGroup>
                      <CRow>
                        <CCol>
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="is_active_true"
                              name="is_active"
                              value="true"
                              innerRef={register({ required: 'Required' })}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="is_active_true"
                            >
                              활성
                            </CLabel>
                          </CFormGroup>
                        </CCol>
                        <CCol>
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="is_active_false"
                              name="is_active"
                              value="false"
                              innerRef={register({ required: 'Required' })}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="is_active_false"
                            >
                              비활성
                            </CLabel>
                          </CFormGroup>
                        </CCol>
                      </CRow>
                    </CFormGroup>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12">
              <CFormGroup>
                <CLabel htmlFor="name">
                  {
                    errors.start_at ? (
                      <span className="label-error">등록 시작 일시는 필수 입력항목입니다.</span>
                    ) : (
                      <>
                        등록 시작 일시 <small>start at</small>
                      </>
                    )
                  }
                </CLabel>
                <div className={classNames('rdt-wrap', { 'is-invalid': errors.start_at })}>
                  <Controller
                    name="start_at"
                    control={control}
                    register={register({ required: true })}
                    render={(props) => (
                      <Datetime
                        {...props}
                        dateFormat="YYYY-MM-DD"
                        timeFormat="HH:mm:ss"
                      />
                    )}
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm:ss"
                  />
                </div>
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12">
              <CFormGroup>
                <CLabel htmlFor="name">
                  {
                    errors.expires_at ? (
                      <span className="label-error">등록 만료 일시는 필수 입력항목입니다.</span>
                    ) : (
                      <>
                        등록 만료 일시 <small>expire at</small>
                      </>
                    )
                  }
                </CLabel>
                <div className={classNames('rdt-wrap', { 'is-invalid': errors.expires_at })}>
                  <Controller
                    name="expires_at"
                    control={control}
                    className={{ 'is-invalid': errors.expires_at }}
                    register={register({ required: true })}
                    render={(props) => (
                      <Datetime
                        {...props}
                        dateFormat="YYYY-MM-DD"
                        timeFormat="HH:mm:ss"
                      />
                    )}
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm:ss"
                  />
                </div>
              </CFormGroup>
            </CCol>
          </CRow>

          <CCard>
            <CCardHeader>
              사용 만료 기간{' '}
              <small>
                쿠폰 등록 후 <strong>사용하기</strong> 할 수 있는 기한
              </small>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol xs="12">
                  <CFormGroup>
                    <CLabel htmlFor="name">만료 타입</CLabel>
                    <CRow>
                      <CCol>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio
                            custom
                            id="use_expire_type_none"
                            name="use_expire_type"
                            value="none"
                            innerRef={register({
                              required: 'Required',
                            })}
                          />
                          <CLabel
                            variant="custom-checkbox"
                            htmlFor="use_expire_type_none"
                          >
                            사용 만료 없음
                          </CLabel>
                        </CFormGroup>

                      </CCol>
                      <CCol>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio
                            custom
                            id="use_expire_type_relative"
                            name="use_expire_type"
                            value="relative"
                            innerRef={register({
                              required: 'Required',
                            })}
                          />
                          <CLabel
                            variant="custom-checkbox"
                            htmlFor="use_expire_type_relative"
                          >
                            등록 후 일정 기간 이내 사용 가능
                          </CLabel>
                        </CFormGroup>

                      </CCol>
                      <CCol>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio
                            custom
                            id="use_expire_type_datetime"
                            name="use_expire_type"
                            value="datetime"
                            innerRef={register({
                              required: 'Required',
                            })}
                          />
                          <CLabel
                            variant="custom-checkbox"
                            htmlFor="use_expire_type_datetime"
                          >
                            만료일 지정됨
                          </CLabel>
                        </CFormGroup>

                      </CCol>
                      <CCol>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio
                            custom
                            id="use_expire_type_expires_at"
                            name="use_expire_type"
                            value="expires_at"
                            innerRef={register({ required: 'Required' })}
                          />
                          <CLabel
                            variant="custom-checkbox"
                            htmlFor="use_expire_type_expires_at"
                          >
                            등록 만료일과 동일
                          </CLabel>
                        </CFormGroup>
                      </CCol>
                    </CRow>
                  </CFormGroup>
                </CCol>
              </CRow>

              {watchUseExpireType === 'relative' && (
                <CRow>
                  <CCol xs="12">
                    <CFormGroup>
                      <CLabel htmlFor="name">만료 기한</CLabel>
                      <CInputGroup
                        className={{ 'is-invalid': errors.use_expires_in_days }}
                      >
                        <CInputGroupPrepend>
                          <CInputGroupText>쿠폰 등록 후 </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          name="use_expires_in_days"
                          placeholder="7"
                          innerRef={register({ required: 'Required' })}
                        />
                        <CInputGroupAppend>
                          <CInputGroupText>일 이후 만료</CInputGroupText>
                        </CInputGroupAppend>
                      </CInputGroup>
                      {
                        errors.use_expires_in_days && (
                          <div className="help-block">
                            만료 기한은 필수 입력항목입니다.
                          </div>
                        )
                      }
                    </CFormGroup>
                  </CCol>
                </CRow>
              )}

              {watchUseExpireType === 'datetime' && (
                <CRow>
                  <CCol xs="12">
                    <CFormGroup>
                      <CLabel htmlFor="name">만료 일시</CLabel>
                      <div className={classNames('rdt-wrap', { 'is-invalid': errors.use_expires_at })}>
                        <Controller
                          name="use_expires_at"
                          className={{ 'is-invalid': errors.use_expires_at }}
                          control={control}
                          register={register({ required: true })}
                          // defaultValue={moment(defaultValues.use_expires_at)}
                          render={(props) => (
                            <Datetime
                              {...props}
                              dateFormat="YYYY-MM-DD"
                              timeFormat="HH:mm:ss"
                            />
                          )}
                          dateFormat="YYYY-MM-DD"
                          timeFormat="HH:mm:ss"
                        />
                      </div>
                    </CFormGroup>
                    {
                      errors.use_expires_at && (
                        <div className="help-block">
                          만료 일시는 필수 입력항목입니다.
                        </div>
                      )
                    }
                  </CCol>
                </CRow>
              )}
            </CCardBody>
          </CCard>

        </CCardBody>
        <CCardFooter>
          <div style={{ padding: '4px 0 8px 0', textAlign: 'right' }}>
            <CButton type="submit" size="lg" color="primary">
              <CIcon name="cil-check" />
              {mode === 'edit' ? '쿠폰 수정' : '쿠폰 등록'}
            </CButton>{' '}
            <CButton type="reset" size="lg" color="danger">
              <CIcon name="cil-ban" /> Reset
            </CButton>
          </div>
        </CCardFooter>
      </CCard>
    </form>
  );
};
