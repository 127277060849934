import React, { useEffect, useState } from 'react';

import BlockUi from 'react-block-ui';
import { CouponForm } from '../../components/CouponForm';
import axios from 'axios';
import config from '../../config';
import moment from 'moment';
import CouponListNav from '../../components/CouponListNav';

export const CouponCreate = (props) => {
  const [courseData, setCourseData] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const { history } = props;

  const getCourseData = () => {
    const endpoint = `${config.API_BASE}/admin/lectures/courses/ids`;
    axios
      .get(endpoint)
      .then((res) => {
        setCourseData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCourseData();
  }, []);

  const onSubmit = (value) => {
    setBlocking(true);
    const endpoint = `${config.API_BASE}/admin/coupons/coupons`;

    ['expires_at', 'start_at', 'use_expires_at'].map((key, idx) => {
      if (moment.isMoment(value[key])) {
        value[key] = value[key].format();
      }
    });

    ['max_use_count', 'use_expires_in_days'].map(
      (key, idx) => {
        if (typeof value[key] === 'string') {
          value[key] = parseInt(value[key]);
        }
      },
    );

    value.course_ids = value.course_ids.map((courseId, idx) =>
      parseInt(courseId),
    );

    value.is_active = value.is_active === 'true';

    axios
      .post(endpoint, value)
      .then((res) => {
        const couponId = res.data.data.id;
        history.push(`/coupons/coupons/${couponId}`);
        setBlocking(false);
      })
      .catch((err) => {
        console.log(err);
        setBlocking(false);
      });
  };

  return (
    <>
      <CouponListNav />
      <BlockUi
        tag="div"
        blocking={blocking}
        message="쿠폰을 생성하고 있습니다."
      >
        <CouponForm courseData={courseData} onSubmit={onSubmit} />
      </BlockUi>
    </>
  );
};
