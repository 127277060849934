import { CBadge, CCol, CRow } from '@coreui/react';

import React from 'react';
import moment from 'moment';

export const CouponDetail = (props) => {
  const {
    name,
    created_at,
    updated_at,
    description,
    courseData,
    voucher_type,
    exchange_course_ids,
    exchange_course_duration,
    discount_course_ids,
    discount_percent,
    max_use_count,
    is_active,
    expires_at,
    start_at,
    use_expires_in_days,
    use_expire_type,
    use_expires_at,
  } = props;

  const couponCourses = courseData.filter((course) => {
    try {
      if (voucher_type === 'exchange') {
        return exchange_course_ids.includes(course.id);
      } else if (voucher_type === 'discount') {
        return discount_course_ids.includes(course.id);
      }
    } catch (e) {
      return false;
    }
  });

  return (
    <>
      <CRow>
        <CCol md={2}>이름</CCol>
        <CCol>{name}</CCol>
      </CRow>
      <CRow>
        <CCol md={2}>쿠폰 설명</CCol>
        <CCol>{description}</CCol>
      </CRow>
      <CRow>
        <CCol md={2}>쿠폰 타입</CCol>
        <CCol>
          <CBadge color="primary">코스 수강권</CBadge>
        </CCol>
        <CCol md={2}>활성 여부</CCol>
        <CCol>
          {is_active ? (
            <CBadge color="success">활성</CBadge>
          ) : (
            <CBadge color="danger">비활성</CBadge>
          )}
        </CCol>
      </CRow>
      <CRow>
        <CCol md={2}>등록 일시</CCol>
        <CCol md={4}>{moment(created_at).format('YYYY-MM-DD hh:mm:ss')}</CCol>
        <CCol md={2}>최종 수정 일시</CCol>
        <CCol md={4}>{moment(updated_at).format('YYYY-MM-DD hh:mm:ss')}</CCol>
      </CRow>
      <hr />
      <CRow>
        <CCol>
          <h5>쿠폰 혜택</h5>
        </CCol>
      </CRow>
      {
        voucher_type === 'exchange' && (
          <>
            <CRow style={{ marginBottom: '1.5em' }}>
              <CCol md={2}>혜택</CCol>
              <CCol>무료 수강 (강의 교환권)</CCol>
            </CRow>
            <CRow>
              <CCol md={2}>코스</CCol>
              <CCol>
                <div className="badge-valign">
                  {couponCourses &&
                  couponCourses.map((el, idx) => (
                    <div key={idx}>
                      <CBadge color="primary">{el.id}</CBadge>{' '}
                      {el.name}
                    </div>
                  ))}
                </div>
              </CCol>
              <CCol md={2}>수강 기간</CCol>
              <CCol>
                <strong>{exchange_course_duration}</strong>일 동안 수강 가능
              </CCol>
            </CRow>
          </>
        )
      }
      {
        voucher_type === 'discount' && (
          <>
            <CRow style={{ marginBottom: '1.5em' }}>
              <CCol md={2}>혜택</CCol>
              <CCol>결제금액 할인 (할인권)</CCol>
            </CRow>
            <CRow>
              <CCol md={2}>코스</CCol>
              <CCol>
                <div className="badge-valign">
                  {couponCourses &&
                  couponCourses.map((el, idx) => (
                    <div key={idx}>
                      <CBadge color="primary">{el.id}</CBadge>{' '}
                      {el.name}
                    </div>
                  ))}
                </div>
              </CCol>
              {/*</CRow>*/}
              {/*<CRow>*/}
              <CCol md={2}>할인율</CCol>
              <CCol>
                <strong>{discount_percent}</strong>% 할인
              </CCol>
            </CRow>
          </>
        )
      }
      <hr />
      <CRow>
        <CCol>
          <h5>쿠폰 코드 설정</h5>
        </CCol>
      </CRow>
      <CRow>
        <CCol md={2}>쿠폰 코드 당 최대 등록 가능 횟수</CCol>
        <CCol md={4}>
          <strong>{max_use_count}</strong>회
        </CCol>
      </CRow>
      <hr />
      <CRow>
        <CCol>
          <h5>등록 및 사용 기간</h5>
        </CCol>
      </CRow>
      <CRow>
        <CCol md={2}>등록 시작 일시</CCol>
        <CCol md={4}>{moment(start_at).format('YYYY-MM-DD HH:mm:ss')}</CCol>
        <CCol md={2}>등록 마감 일시</CCol>
        <CCol md={4}>{moment(expires_at).format('YYYY-MM-DD HH:mm:ss')}</CCol>
      </CRow>
      <CRow>
        <CCol md={2}>사용 마감 방식</CCol>
        <CCol>
          {use_expire_type === 'none' && '사용 만료 없음'}
          {use_expire_type === 'datetime' && '만료일 지정됨'}
          {use_expire_type === 'relative' && '등록 후 일정 기간 이내 사용 가능'}
          {use_expire_type === 'expires_at' && '등록 마감 일시 이내 사용 가능'}
        </CCol>
        <CCol md={2}>사용 마감 일시</CCol>
        <CCol>
          {use_expire_type === 'none' && '-'}
          {(use_expire_type === 'datetime' ||
            use_expire_type === 'expires_at') &&
          moment(use_expires_at).format('YYYY-MM-DD HH:mm:ss')}
          {use_expire_type === 'relative' && (
            <>
              쿠폰 등록 후 <strong>{use_expires_in_days}</strong>일
            </>
          )}
        </CCol>
      </CRow>
    </>
  );
};
